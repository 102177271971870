import React, { useEffect, useRef }  from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";


import Layout from "../components/Layout";
import BlogRoll from "../components/BlogRoll";
import '../styles/global.css'

import { IconFileText, IconArrowRight, IconRobot,IconReportSearch} from '@tabler/icons-react'


import stepping from "../img/stepping.svg";
import shape1 from "../img/shape-1.svg";
import shape2 from "../img/shape-2.svg";
import shape3 from "../img/shape-3.svg";

import grid1 from "../img/grid-1.svg";
import grid2 from "../img/grid-2.svg";

import ContactModal from '../components/contactModal';
import VideoOnScroll from "../components/VideoOnScroll";
 


// eslint-disable-next-line
export const IndexPageTemplate = ({
  image,
  title,
  heading,
  subheading,
  mainpitch,
  description,
  intro,
}) => {
  
  const logo = `/img/logo.svg`;
  const heroImage = `url(${image.childImageSharp.gatsbyImageData.images.fallback.src})`;
  const bgBlueDark = `url(/img/noisy-gradient-blue-dark.png)`;
  const bgBlueGreen = `url(/img/bg-green-blue-gradient.png)`;
  const stepping = `/img/stepping.svg `;
  return (
    <div>
      <div class="relative h-[1200px] w-full flex justify-center">
   
        <div className="max-w-5xl mx-auto px-3 py-32 z-20 absolute w-full">
          <section className="lg:flex items-center justify-center z-20">
            <div class="lg:w-2/3">
              <div className="text-center lg:text-left text-7xl lg:text-8xl">Document. <br/>Automate. <br/>Chill.</div>
              <div className="text-center lg:text-left text-xl mt-6">
                StepWise is an Open Source centralized hub for technical teams to manage, automate, and execute complex operational processes 
              </div>
              <div className="flex justify-center lg:justify-start mt-6">
                <Link to="/contact" className="transition ease-in duration-200 bg-black text-white text-md rounded-xl px-6 py-3 mr-3">Contact Us</Link>
                <Link to="/" className="hidden transition ease-in duration-200 bg-white text-indigo-500 border-2 border-indigo-500 text-md rounded-xl px-6 py-3 hover:shadow-lg hover:shadow-indigo-200 ">Download Now</Link>
              </div>
            </div>
            <div className="hidden lg:block mt-6 lg:mt-0 flex justify-center">
             <img src={stepping} alt="StepWise" className="mx-auto w-[300px] lg:w-[600px]" />
            </div>
          </section>
           
          <section className="mt-12 z-40 max-w-3xl mx-auto">
            <div className="bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 rounded-3xl p-1 z-40 shadow-2xl shadow-pink-400">
              <div className="bg-white h-full w-full rounded-[20px]">
              <div className="relative pb-[56.25%] h-0 rounded-2xl" ><iframe src="https://www.loom.com/embed/899cf93048694086ab139238808a23ad?sid=b5222d7f-1475-458b-821e-d4d748708f62" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen 
              className="absolute top-0 left-0 w-full h-full rounded-[20px]"
             ></iframe></div>
              </div>
            </div>
         
          </section>
          
        </div>
        <div className="glow z-10">          
        </div>
      </div>
      <div className="max-w-5xl mx-auto bg-white px-3">
        <div className="text-6xl text-gray-800 ">
          <span className="font-bold">3 Ways Teams use <span className="font-black text-transparent bg-clip-text bg-gradient-to-r from-purple-700 to-pink-600">StepWise</span> to keep</span>
            <br/>  their IT Ops in Lock Step
        </div>

        <div className="space-y-24 mt-24">

          <section class="flex flex-col md:flex-row items-center space-y-12 justify-between">
            <div className="flex space-x-6 mr-12">
              <IconFileText size={48} className="flex-none"/>
              <div className="flex flex-col justify-between">
                <div>
                  <div className="text-4xl text-gray-800 font-bold">
                  Documentation
                  </div>
                  <div className="mt-4">
                    A documentation tool installable On-Prem! Own your internal procedures and automate them with StepWise.
                  </div>
                  <div className="mt-4">
                    StepWise delivers a first class experience for managing crictical information about your infrastructure and operations. 
                  </div>
                  <div className="mt-4">
                    <Link to="/contact" className="mt-4 inline-block transition ease-in duration-200 bg-gray-800 text-white border-2 border-black rounded-xl px-5 py-2 hover:shadow-lg hover:shadow-indigo-200 ">Contact Us</Link>
              
                  </div>
                </div>
                <div>
                  <ul className="space-y-3 mt-4">
                      <li className="">
                        <div className="flex flex-wrap justify-start">
                          <div className="rounded-full bg-gray-200 text-gray-500 px-4 py-1 text-sm mb-2 mr-2">
                            Collaborative Editing
                          </div>
                          <div className="rounded-full bg-gray-200 text-gray-500 px-4 py-1 text-sm mb-2 mr-2">
                            Access Controls
                          </div>
                          <div className="rounded-full bg-gray-200 text-gray-500 px-4 py-1 text-sm mb-2 mr-2">
                            Design Compatibility
                          </div>
                          <div className="rounded-full bg-gray-200 text-gray-500 px-4 py-1 text-sm mb-2 mr-2">
                            Runbook Automation
                          </div>
                        </div>
                      </li>
                    </ul>
                </div>
              </div>
            </div>
            <div className="border-2 border-gray-800 flex-none bg-white rounded-2xl shadow-xl w-96 h-96">
         
            <video  width="386" height="386" muted autoplay loop controls webkit-playsinline playsinline className="rounded-[16px]">
              <source src="/video/doc.mp4"type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            </div>
          </section>
          <section class="flex flex-col md:flex-row items-center space-y-12 justify-between">
            <div className="flex space-x-6 mr-12">
              <IconRobot size={48} className="flex-none"/>
              <div className="flex flex-col justify-between">
                <div>
                  <div className="text-4xl text-gray-800 font-bold">
                    Automation
                  </div>
                  <div className="mt-4">
                    Embed Automation steps into your documentation. StepWise will execute them for you or you can manually acknowledge them.
                  </div>
                  <div className="mt-4">
                    StepWise delivers a first class experience for managing crictical information about your infrastructure and operations.
                  </div>
                  <div className="mt-4">
                    <Link to="/contact" className="mt-4 inline-block transition ease-in duration-200 bg-gray-800 text-white border-2 border-black rounded-xl px-5 py-2 hover:shadow-lg hover:shadow-indigo-200 ">Contact Us</Link>
              
                  </div>
                </div>
                <div>
                  <ul className="space-y-3 mt-4">
                      <li className="">
                        <div className="flex flex-wrap justify-start">
                          <div className="rounded-full bg-gray-200 text-gray-500 px-4 py-1 text-sm mb-2 mr-2">
                            Manual Steps
                          </div>
                          <div className="rounded-full bg-gray-200 text-gray-500 px-4 py-1 text-sm mb-2 mr-2">
                            Automated Steps
                          </div>
                          <div className="rounded-full bg-gray-200 text-gray-500 px-4 py-1 text-sm mb-2 mr-2">
                            Consume APIs
                          </div>
                        
                        </div>
                      </li>
                    </ul>
                </div>
              </div>
            </div>
            <div className="border-2 border-gray-800 flex-none bg-white rounded-2xl shadow-xl w-96 h-96">
         
            <video  width="386" height="386" muted autoplay loop controls webkit-playsinline playsinline className="rounded-[16px]">
              <source src="/video/automation.mp4"type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            </div>
          </section>
          <section class="flex flex-col md:flex-row items-center space-y-12 justify-between">
            <div className="flex space-x-6 mr-12">
              <IconReportSearch size={48} className="flex-none"/>
              <div className="flex flex-col justify-between">
                <div>
                  <div className="text-4xl text-gray-800 font-bold">
                    Reporting & Compliance
                  </div>
                  <div className="mt-4">
                    Keep evidence of the entire process not just the ones that were automated.
                  </div>
                  <div className="mt-4">
                    Meet compliance and audit requirements with ease.
                  </div>
                  <div className="mt-4">
                    <Link to="/contact" className="mt-4 inline-block transition ease-in duration-200 bg-gray-800 text-white border-2 border-black rounded-xl px-5 py-2 hover:shadow-lg hover:shadow-indigo-200 ">Contact Us</Link>
              
                  </div>
                </div>
                <div>
                  <ul className="space-y-3 mt-4">
                      <li className="">
                        <div className="flex flex-wrap justify-start">
                          <div className="rounded-full bg-gray-200 text-gray-500 px-4 py-1 text-sm mb-2 mr-2">
                            Execution History
                          </div>
                          <div className="rounded-full bg-gray-200 text-gray-500 px-4 py-1 text-sm mb-2 mr-2">
                            MTTR
                          </div>
                         
                        </div>
                      </li>
                    </ul>
                </div>
              </div>
            </div>
            <div className="border-2 border-gray-800 flex-none bg-white rounded-2xl shadow-xl w-96 h-96">
         
              <img src="/img/reporting.png" className="rounded-xl"/>
            </div>
          </section>
        </div>
        
        
      </div>
      
      <section className="mt-24 max-w-7xl mx-auto px-3">
        <div class="bg-[radial-gradient(109.40%_189.55%_at_94.76%_6.29%,rgba(97,0,255,0.10)_0%,rgba(97,0,255,0.00)_100%)] p-8 rounded-xl">
            <div className="text-xl text-gray-800 font-bold text-center mb-4 uppercase tracking-widest">
              Featured Posts
            </div>
            <BlogRoll postCount={3} />
        </div>
      </section>

      <div className="max-w-5xl mx-auto">
        <section className="pb-24 bg-cover px-3 md:px-12 bg-center py-24">
          <div className="max-w-6xl mx-auto">
            <div className="md:px-6">
              <h2 className="text-3xl md:text-4xl lg:text-4xl xl:text-4xl 2xl:text-7xl leading-tightest text-center ">
                <span className="text-black">Simple Yet Powerful UI to Maximize Your Goals</span>
              </h2>
            </div>
        
          <img src="/img/product.png" alt="StepWise"
          
          className="mt-12"></img>
          </div>
        </section>
        <section>
          <div className="bg-gray-300/50 rounded-xl gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6">
              <img className="w-full dark rounded-lg" src="/img/mockup-2.png" alt="dashboard image"/>
      
              <div className="mt-4 md:mt-0">
                  <h2 className="mb-4 text-4xl tracking-tight font-semibold text-gray-800">The Full Spectrum Automation Suite: Document, Automate, Verify</h2>
                  <p className="mb-6 font-light text-gray-500 md:text-lg text-gray-800">Automation doesn't happen overnight, take the first step with documentation. Evolve towards full automation </p>
                  <ContactModal btnSize="md" leadTag="Home Product View" />
              </div>
          </div>
        </section>
      </div>

    
      
     
      
      
  
   
    
    </div>
  );
};

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout scrollNavbar={true}>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
        intro={frontmatter.intro}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        heading
        subheading
        mainpitch {
          title
          description
        }
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                gatsbyImageData(width: 240, quality: 64, layout: CONSTRAINED)
              }
            }
            text
          }
          heading
          description
        }
      }
    }
  }
`;
